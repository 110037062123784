/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.manufacture-container {
  background: #fff;
  padding: 16px 32px;
  margin-bottom: 24px;
}
.manufacture-container .title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  margin-bottom: 16px;
  color: #14837B;
}
.manufacture-container.empty {
  color: #f5222d;
  font-size: 1.2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 48px;
}
