/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.input-block {
  margin-bottom: 8px;
}
.input-block label {
  color: #1890ff;
}
.input-block:last-child {
  margin-bottom: 0;
}
.input-block.view label::after {
  content: " : ";
}
.drone-manufacture-table {
  min-width: 1024px;
}
