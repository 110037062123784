/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.manufacture-pane {
  margin-bottom: 32px;
}
.manufacture-pane .header {
  margin-bottom: 16px;
}
.manufacture-pane .header .title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #D22027;
  text-decoration: underline;
}
.manufacture-pane .header .actions {
  display: inline-block;
  margin-left: 24px;
}
.manufacture-pane .header .actions > button {
  font-size: 0.95em;
  min-width: 85px;
}
.manufacture-actions {
  margin-top: 12px;
}
